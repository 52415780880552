import React, { useState, useEffect } from "react"
import Icon from "./core/Icon"

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    // Show the button when the user scrolls down by a certain amount
    const toggleVisibility = () => {
      if (window.scrollY > 300) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    }

    window.addEventListener("scroll", toggleVisibility)

    return () => {
      window.removeEventListener("scroll", toggleVisibility)
    }
  }, [])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  return isVisible ? (
    <div
      className={`scrolltotop flex justify-center items-center rounded-md mb-2 h-12 w-12 fixed bottom-24 cursor-pointer lg:right-8 md:right-8 right-5 ${isVisible ? "" : "d-none"}`}
      onClick={scrollToTop}
    >
      <i
        className={`icon-slider-left-arrow flex justify-center icon pr-2`}
        style={{ fontSize: "30px", color: 'white', transform: 'rotate(90deg)', padding: "0px" }}
      />
    </div>
  ) : null
}

export default ScrollToTopButton
