import React from "react"

const Icon = ({ iconName, className, theme }) => {
  if (!theme) {
    theme = "#335fff"
  }
  return (
    <i
      className={`${iconName} ${className}  icon `}
      style={{ fontSize: "70px", color: theme }}
    />
  )
}

export default Icon
